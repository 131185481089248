
.labels .labels__item {
    position: fixed;
    left: -100vw;
    top: 0;
    z-index: 2;
    transition: opacity .5s;
}
.labels .labels__item:not(.master)::after {
    content : "";
    background: white;
    position: absolute;
    width:100%;
    height: 100%;
    top:0;
    left:-50%;
    transform: scale(0.8, 0.9);
    border-radius: 0.25em;
    z-index: -1;
    transition: all .5s;
}
.labels .labels__item--show{
    opacity: 1;
}
.labels .labels__item--hide{
    opacity: 0;
}
.labels .labels__item--visible{
    left:0
}

.labels .labels__item > .inner {
    margin-left:-50%;
    width: 7em;
    border-radius: 0.25em;
}

.labels .labels__item > .inner .labels__title {
    float: left;
    width: 5em;
    padding: 0.2em 0.5em;
    background: #fff;
    border-top-left-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    transition: all .3s;
}

.labels .labels__item > .inner .labels__value {
    position: relative;
    float: left;
    width: 2em;
    padding: 0.2em 0.5em;
    background: var(--secondary-color);
    color: white;
    text-align: center;
    border-top-right-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    transition: background .3s;
}

.labels .labels__item.animated::after  {
    transform: scale(1.2, 1.4);
    opacity:0;
}

.labels .labels__item.animated > .inner .labels__value {
    background: var(--primary-color);
}

.labels__fav {
    position: absolute;
    width: 14px;
    height: 14px;
    top: -8px;
    right: -5px;
}

.labels__fav img {
    width: 100%;
}


.labels .labels__item.master > .inner {
    margin-left:auto;
    width: 8em;
    border-radius: 1.3em;
}



.labels .labels__item.master > .inner .labels__title {
    text-align: center;
    border-radius: 1.3em!important;
    width: 8em;
}

