.primary.narrow.list-view {
  background: none;
}
.list-view {
  padding: 2rem;
  position: relative;

}
.list-view:not(.loading)
{

  height: auto !important;
}


.list-view .inner {
  background: #fff;
}

.list-view table tr {cursor:pointer}

.table .thead-dark th {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.list-view .property-status-icon {
  position: relative;
  display: inline-block;
  margin-left: 1rem;

  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;

}

.list-view .property-status-icon.available {
  background: var(--available-color);
}

.list-view .property-status-icon.sold {
  background: var(--sold-color);
}
.list-view .property-status-icon.reserved {
  background: var(--option-color);
}
.list-view .property-status-icon.not_released {
  background: var(--not-released-color);
}

.list-view th button {
  color: white;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.btn-list-sort {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.btn-list-sort .icon{
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  margin-right: 0.4em;
  background: white;

}
.btn-list-sort .icon.icon-up{
  mask-image: url('../img/icon-arrow-up.svg');
  mask-repeat: no-repeat;
  mask-size: 95%;
  mask-position: center;
}
.btn-list-sort .icon.icon-down{
  mask-image: url('../img/icon-arrow-down.svg');
  mask-repeat: no-repeat;
  mask-size: 95%;
  mask-position: center;
}