.building {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.building .main-title {
  position: fixed;
  left: 2.5em;
  top: 2.5em;
  color: #fff;
  z-index: 20;
}

.building .main-title .number {
  font-size: 2em;
  line-height: 0.8em;

}

.building > .inner {
  position: absolute;
  left: 0;
  top: 50%;
  width: auto;
  transform: translateY(-50%);
}

.building .main-image {
  width: 100vw;
  height: auto;
}

.building .paths svg {
  position: absolute;
  left:0;
  top:0;
  height: auto;
}

.building .paths svg .cls-1 {
  opacity: 0;
  transition: opacity 0.4s;
  cursor: pointer;
  fill: var(--available-color);
}

.building .paths svg .cls-1:hover {
  opacity: 0.4;
  transition: opacity 0.2s;
}

.building .paths.light-effect-1 svg .cls-1 {
  fill: #ccaa1d;
}

.building .paths.light-effect-1 svg {
  filter: blur(15px);
  mix-blend-mode: overlay;
}

.building .paths.light-effect-1 svg g:hover {
  opacity: 0.85;
}

.building.tb1 .paths.light-effect-1  svg .cls-1:hover {
  opacity: 0.6;
}

.labels.debug .item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 2px;
  background: rgba(255,0,0,1);
}

.building .labels.debug {
  width: 100%;
  height: 2px;
  background: rgba(255,0,0,1);
}
.svg__link.sold .cls-1{
  fill : var(--sold-color) !important;
}
.svg__link.option .cls-1{
  fill : var(--option-color) !important;
}
.svg__link.signed-contract .cls-1{
  fill : var(--signed-contract-color) !important;
}
.svg__link.not-released .cls-1{
  fill : var(--not-released-color) !important;
}





@media (max-aspect-ratio: 16/9) {

  .building .main-image {
    position: absolute;
    width: auto;
    height: 100vh;
  }

  .building > .inner {
    position: absolute;
    left: 50%;
    top: 0;
    width: 177.7777vh;
    height: 100vh;
    transform: translateX(-50%);
  }








}



