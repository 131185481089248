.masterplan {
  position: fixed;
  width: 100vw!important;
  height: 100vh!important;
  overflow: hidden;
  z-index: 101;
}


.masterplan > .inner {
  position: absolute;
  left: 0;
  top: 50%;
  width: auto;
  transform: translateY(-50%);
}

.masterplan .main-image {
  width: 100vw;
  height: auto;
}

.masterplan .paths svg {
  position: absolute;
  left:0;
  top:0;
  height: auto;
}

.masterplan .paths svg .cls-1 {
  opacity: 0;
  transition: opacity 0.4s;
  cursor: pointer;
  fill: var(--available-color);
}

.masterplan .paths svg .cls-1:hover {
  opacity: 0.4;
  transition: opacity 0.2s;
}

.masterplan .paths.light-effect-1 svg .cls-1 {
  fill: #ccaa1d;
}

.masterplan .paths.light-effect-1 svg {
  filter: blur(15px);
  mix-blend-mode: overlay;
}

.masterplan .paths.light-effect-1 svg g:hover {
  opacity: 0.85;
}

.masterplan.tb1 .paths.light-effect-1  svg .cls-1:hover {
  opacity: 0.6;
}

.labels.debug .item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 2px;
  background: rgba(255,0,0,1);
}

.masterplan .labels.debug {
  width: 100%;
  height: 2px;
  background: rgba(255,0,0,1);
}
.svg__link.sold .cls-1{
  fill : var(--sold-color) !important;
}
.svg__link.option .cls-1{
  fill : var(--option-color) !important;
}
.svg__link.not-released .cls-1{
  fill : var(--not-released-color) !important;
}


.hide-masterplan
{
  opacity: 0;
  visibility: hidden;

}


@media (max-aspect-ratio: 16/9) {

  .masterplan .main-image {
    position: absolute;
    width: auto;
    height: 100vh;
  }

  .masterplan > .inner {
    position: absolute;
    left: 50%;
    top: 0;
    width: 177.7777vh;
    height: 100vh;
    transform: translateX(-50%);
  }








}



