.property-view
{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.property-view .property-view-container {
    position: relative;
    width: calc(100% - 100px);
    height: 70%;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px -2px rgba(0, 0, 0, 0.25);
}
.property-view.view-fullscreen .property-view-container {
    height: 100%;
    width: 100%;
}
.property-view.view-fullscreen .col-9 {
    flex: 0 0 80%;
    max-width: 80%;
}
.property-view.view-fullscreen .col-3 {
    flex: 0 0 20%;
    max-width: 20%;
}
.main-container
{
    position: relative;
    width: 100%;
    height: 100%;
}

.property-details-container
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-left: 1px solid var(--primary-border-color);
}
.property-view-container .close-button {
    top: 0;
    right: 0;
    width: 3em;
    height: 3em;
    border-left : 1px solid var(--primary-border-color);
    border-bottom : 1px solid var(--primary-border-color)
}
.property-view-container .close-button > div {
    width: 50%;
    left: 8px;
    top: 17px;
}
.property-title {
    padding: 1.5rem 2rem;
    font-size: 3.5em;
    font-weight: normal;
    border-bottom: 1px solid var(--primary-border-color);
    margin-bottom: 0;
}
.property-title span {
    font-size: 1.8rem;
    font-weight: 100;
    color :#333
}
.property-price
{
    padding: 1rem 2rem;
    font-size:1.8em;
    border-bottom: 1px solid var(--primary-border-color);
    margin-bottom: 0;
}

.delivery-date
{
    padding: 1rem;
    padding-left: 2rem;
    font-size:1em;
    border-bottom: 1px solid var(--primary-border-color);
    margin-bottom: 0;
}
.fav-share-container {
    background: #F8F8F8;
    display: flex;
    padding: 2em;
    border-bottom: 1px solid var(--primary-border-color);
}

.property-info {
    font-size: 1.2em;
}

.action-buttons-container
{
    padding: 3rem 0px;
}
.action-buttons-container button
{
    display: inline-block;
    margin-right:1rem
}


.floor-plan-button,.tour-360-button
{
    color : var(--primary-color);
    cursor:pointer;
    display:inline-block;
    margin-right: 1.5rem;
}

.status-label
{
    position: absolute;
    top: 0;
    left: 1em;

    background: #fff;
    font-size: 1.4em;
    padding: 0.5em 1.5em;
    z-index: 3;
}

.property-thumb-swiper {
    position: absolute !important;
    height: 100px !important;
    bottom: 0;
    box-sizing: border-box !important;
    padding: 10px !important;

}

.property-thumb-swiper  .swiper-slide {
    border-radius: 10px;
    opacity: 0.8;
    filter: brightness(80%);
}

.property-thumb-swiper .swiper-slide-thumb-active {
    filter: brightness(120%);
    opacity: 1;
}

.property-thumb-swiper  .swiper-slide img {
    border-radius: 10px;
}
.property-thumb-swiper .swiper-wrapper {
    justify-content: center;
}


.action-button
{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.7em 2em;
    font-weight: normal;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    margin-left: 10px;
}

.action-button:hover
{
    color: white;
    background: var(--primary-color);
}

.bottom-buttons button,
.bottom-buttons a {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    color: #333;
    padding: 2em;
    border-bottom: 1px solid var(--primary-border-color);
    padding-left: 4.2em;
    border-left:3px solid white;
    background: white;
}
.bottom-buttons button.active {
    border-left:3px solid var(--primary-color)
}
.bottom-buttons button:first-child {
    border-top: 1px solid var(--primary-border-color);
}
.bottom-buttons button:last-child {
    border-bottom: 1px solid var(--primary-border-color);
}
.bottom-buttons a:before,
.bottom-buttons button:before{
    content: '';
    position: absolute;
    left: 2em;
    width: 1.5em;
    height: 1.5em;
    background-color: var(--primary-color);
    mask-repeat: no-repeat;
    mask-size: 95%;
    mask-position: center;

}
.bottom-buttons button.btn-information:before,a.btn-information:before {
    mask-image: url('../img/icon-info.svg');
}
.bottom-buttons button.btn-images:before {
    mask-image: url('../img/icon-images.svg');
}
.bottom-buttons button.btn-floorplan:before {
    mask-image: url('../img/icon-floorplan.svg');
}
.bottom-buttons button.btn-360:before,a.btn-360:before {
    mask-image: url('../img/icon-360.svg');
}
.bottom-buttons button.btn-finance:before {
    mask-image: url('../img/icon-finance.svg');
}
.bottom-buttons button:hover,
.bottom-buttons a:hover{
    background-color: var(--primary-color);
    border-left:3px solid var(--primary-color);
    color: white;
}
.bottom-buttons button:hover:before,
.bottom-buttons a:hover:before{
    background-color: white;
}

.information-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.information-content .information-content-wrap{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-border-color);;

}

.information-content .information-content-wrap > div:not(:last-child) {
    border-bottom: 1px solid var(--primary-border-color);
}
.information-content .information-content-wrap > div {
    display: flex;
}
.information-content .information-content-wrap > div span {
    display: block;
    padding: 1em;
    flex-grow: 1;
    min-width: 200px;
}

.information-content .information-content-wrap > div span:last-child {
    border-left: 1px solid var(--primary-border-color);;
    text-align: right;
    font-weight: bold;
}
@media (max-width: 1440px) {

    .property-view .property-view-container {

        height:auto;

    }

}

.swiper-slide img {
    user-select: none;
}